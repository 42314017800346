/* Global colourPicker */
define(['jquery'], function($) {
  

  var colourPicker = {

    _init: function() {
      $('.options-set').show();

      // Copies hex values from .variant-shade options and creates colour swatches
      if ($('select.variant-shade option')) {
        $('.options-set').find('li').remove(); // Remove the original swatch blocks

        var $options = $('select.variant-shade option'); //find the options returned by ajax

        $options.each(function(index) { // loop through and add the values from .variant-shade to LIs
          var $this = $(this),
            value = $this.attr('value'),
            colour = $this.attr('rel'),
            name = $this.text();

          if (index > 0) { // add values to all but first li
            $('.options-set').append('<li rel="' + value + '" title="' + name
              + '" style="background-color: ' + colour + '">'
              + name + '</li>');
          }

        });

        var selected_colour = $('.variant-shade option:selected').attr('value');
        $('.options-set').find('li[rel=' + selected_colour + ']').addClass('active'); // set clicked swatch to active based on chosen option in variant-shade
      }
    }
  };

  return colourPicker._init;
});
